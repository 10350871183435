<template>
<div class="container">
    <div class="header">
        <img
            :src="isDarkMode ? require('../assets/images/base/ic_public_back_dark.png') : require('../assets/images/base/ic_public_back.png')"
            alt="">
        <span>睡眠报告</span>
    </div>
    <div class="top">
        <div class="times">
            <div :class="{'active':currTimeType == 'week'}" @click="currTimeType = 'week'">
                <span>周</span>
            </div>
            <div :class="{'active':currTimeType == 'mon'}" @click="currTimeType = 'mon'">
                <span>月</span>
            </div>
        </div>
        <div class="time-box">
            <div @click="prevTime">
                <span class="iconfont icon-xiangzuo1"></span>
            </div>
            <div>
                <span v-text="currTimeType == 'mon' ? monTimes : weekTimes"></span>
            </div>
            <div @click="nextTime">
                <span class="iconfont icon-xiangyou1"></span>
            </div>
        </div>
    </div>

    <div class="content">
        <div class="box" v-if="btnStatus">
            <div class="sleep-data-box">
                <div class="title">
                    <span>睡眠时长</span>
                </div>
                <div class="sleep-data" ref="sleep1"></div>
            </div>

            <div class="sleep-data-box">
                <div class="title">
                    <span>深睡时间</span>
                </div>
                <div class="sleep-data" ref="sleep2"></div>
            </div>

            <div class="sleep-data-box">
                <div class="title">
                    <span>清醒次数</span>
                </div>
                <div class="sleep-data" ref="sleep3"></div>
            </div>

            <div class="sleep-data-box">
                <div class="title">
                    <span>入睡时间</span>
                </div>
                <div class="sleep-data" ref="sleep4"></div>
            </div>

            <div class="sleep-data-box">
                <div class="title">
                    <span>醒来时间</span>
                </div>
                <div class="sleep-data" ref="sleep5"></div>
            </div>

            <div class="box-sleep-title">
                <span>本周统计</span>
            </div>
            <div class="box-count">
                <div class="box-count-col">
                    <div class="list diver">
                        <div>
                            <span v-text="count.deep_sleep_time ? count.deep_sleep_time['h'] : ''"></span>
                            <span class="unit">时</span>
                            <span v-text="count.deep_sleep_time ? count.deep_sleep_time['i'] : ''"></span>
                            <span class="unit">分</span>
                        </div>
                        <div>
                            <span class="introduce">平均深睡时间</span>
                        </div>
                    </div>
                    <div class="list diver">
                        <div>
                            <span v-text="count.all_sleep_time ? count.all_sleep_time['h'] : ''"></span>
                            <span class="unit">时</span>
                            <span v-text="count.all_sleep_time ? count.deep_sleep_time['i'] : ''"></span>
                            <span class="unit">分</span>
                        </div>
                        <div>
                            <span class="introduce">平均睡眠时长</span>
                        </div>
                    </div>
                    <div class="list">
                        <div>
                            <span v-text="count.wakeup_count ? count.wakeup_count : 0"></span>
                            <span class="unit">次</span>
                        </div>
                        <div>
                            <span class="introduce">平均清醒次数</span>
                        </div>
                    </div>
                </div>
                <div class="box-count-col">
                    <div class="list diver">
                        <div>
                            <span v-text="count.fall_asleep_time ? count.fall_asleep_time['h'] + ':' + count.fall_asleep_time['i'] : ''"></span>
                            <span class="unit-2">凌晨</span>
                        </div>
                        <div>
                            <span class="introduce">平均入睡时间</span>
                        </div>
                    </div>
                    <div class="list diver">
                        <div>
                            <span v-text="count.use_log ? count.use_log : 0"></span>
                            <span class="unit">次</span>
                        </div>
                        <div>
                            <span class="introduce">使用睡眠仪时间</span>
                        </div>
                    </div>
                    <div class="list">
                        <div>
                            <span v-text="count.wakeup_time ? count.wakeup_time['h'] + ':' + count.wakeup_time['i'] : ''"></span>
                            <span class="unit-2">清晨</span>
                        </div>
                        <div>
                            <span class="introduce">平均醒来时间</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="count-tip">
                <div style="width: 14%">
                    <span>提示：</span>
                </div>
                <div style="width: 85%">
                    <p>1.本周监测记录完整，请继续保持每周有5天以上的监测记录；</p>
                    <p>2.保持每周使用睡眠仪次数不少于5次；</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    name: "sleepData",
    data() {
        return {
            isDarkMode: false,
            data: {},
            btnStatus: false,
            currTimeType: 'week', // week mon
            weekTime: '2021-12-1/2021-12-7',
            weekTimes: '2021年12月1日-2021年12月7日',
            monTime: '2021-12',
            monTimes: '2021年12月',
            arr: [],
            arrIndex: [],
            count: []
        }
    },
    watch: {
        currTimeType() {
            this.getData()
        }
    },
    methods: {
        GetMonday(dd) {
            var week = dd.getDay(); //获取时间的星期数
            var minus = week ? week - 1 : 6;
            dd.setDate(dd.getDate() - minus); //获取minus天前的日期
            var y = dd.getFullYear();
            var m = dd.getMonth() + 1; //获取月份
            var d = dd.getDate();
            var time = [y + "-" + m + "-" + d, y + "年" + m + "月" + d + '日']
            return time;
        },
        GetSunday(dd) {
            var week = dd.getDay(); //获取时间的星期数
            var minus = week ? week - 7 : 6;
            dd.setDate(dd.getDate() - minus); //获取minus天前的日期
            var y = dd.getFullYear();
            var m = dd.getMonth() + 1; //获取月份
            var d = dd.getDate();
            var time = [y + "-" + m + "-" + d, y + "年" + m + "月" + d + '日']
            return time;
        },
        getMon() {
            let dd = new Date();
            var y = dd.getFullYear();
            var m = dd.getMonth() + 1; //获取月份
            var time = [y + "-" + m, y + "年" + m + "月"]
            return time;
        },
        getSomeDayDate(date, num, interval) {
            const nowDate = new Date(date);
            if (interval === 'after') {
                nowDate.setDate(nowDate.getDate() + num);
            } else if (interval === 'before') {
                nowDate.setDate(nowDate.getDate() - num);
            }
            const year = nowDate.getFullYear();
            const month = nowDate.getMonth() + 1 < 10 ? `${(nowDate.getMonth() + 1)}` : nowDate.getMonth() + 1;
            const day = nowDate.getDate() < 10 ? `${nowDate.getDate()}` : nowDate.getDate();
            let time = [`${year}-${month}-${day}`, `${year}年${month}月${day}日`]
            return time;
        },
        clickBtn() {
            this.btnStatus = this.btnStatus ? false : true;
            this.$axios.post('/api/changeDiary', {
                token: this.token,
                state: this.btnStatus
            }).then(() => {
                this.getData();
            })
        },
        prevTime() {
            if (this.currTimeType == 'mon') {
                let time = this.monTime.split('-');
                let year = time[0];
                let mon = parseInt(parseInt(time[1]) - 1);
                if (mon < 1) {
                    mon = '12';
                    year = parseInt(year) - 1;
                }
                if (mon <= 9) {
                    mon = '0' + mon;
                }
                this.monTime = year + '-' + mon;
                this.monTimes = parseFloat(year) + '年' + parseFloat(mon) + '月';
            } else {
                let time = this.weekTime.split('/');
                let start = this.getSomeDayDate(time[0], 7, 'before');
                let end = this.getSomeDayDate(time[1], 7, 'before');
                this.weekTimes = start[1] + '-' + end[1];
                this.weekTime = start[0] + '/' + end[0];
            }
            this.getData()
        },
        nextTime() {
            if (this.currTimeType == 'mon') {
                let time = this.monTime.split('-');
                let year = time[0];
                let mon = parseInt(parseInt(time[1]) + 1);
                if (mon > 12) {
                    mon = '1';
                    year = parseInt(year) + 1;
                }
                if (mon <= 9) {
                    mon = '0' + mon;
                }
                this.monTime = year + '-' + mon;
                this.monTimes = parseFloat(year) + '年' + parseFloat(mon) + '月';
            } else {
                let time = this.weekTime.split('/');
                let start = this.getSomeDayDate(time[0], 7, 'after');
                let end = this.getSomeDayDate(time[1], 7, 'after');
                this.weekTimes = start[1] + '-' + end[1];
                this.weekTime = start[0] + '/' + end[0];
            }
            this.getData()
        },
        createEcharts(dom, data, index) {
            let that = this;
            let myEchart = echarts.init(dom);
            let xData = this.currTimeType == 'week' ?
                ['1', '2', '3', '4', '5', '6', '7'] :
                ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
            let max = 0;
            let min = 0;
            let interval = 0;
            switch (index) {
                case 0:
                    max = 9;
                    interval = 3;
                    break;
                case 1:
                    max = 3;
                    interval = 1;
                    break;
                case 2:
                    max = 6;
                    interval = 2;
                    break;
                case 3:
                    max = 30;
                    interval = 3;
                    min = 21;
                    break;
                case 4:
                    max = 9;
                    interval = 3;
                    break;
            }

            let option = {
                grid: {
                    left: '10px',
                    top: '10px',
                    right: '0',
                    bottom: '5px',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: xData,
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgb(152,152,152)'
                        }
                    },
                    nameTextStyle: {
                        color: 'rgb(152,152,152)'
                    }
                },
                yAxis: {
                    type: 'value',
                    position: 'right',
                    min: min,
                    max: max,
                    interval: interval,
                    axisLabel: {
                        fontSize: 10,
                        formatter: function (value) {
                            if (index == 0) {
                                if (value == 9) {
                                    return '9(小时)';
                                } else {
                                    return value;
                                }
                            } else if (index == 1) {
                                if (value == 3) {
                                    return '3(小时)';
                                } else {
                                    return value;
                                }
                            } else if (index == 2) {
                                if (value == 6) {
                                    return '6(次) ';
                                } else {
                                    return value;
                                }
                            } else if (index == 3) {
                                if (value == 24) {
                                    return '0(凌晨)';
                                } else if (value == 30){
                                    return '6(清晨)';
                                } else {
                                    return value > 24 ? value - 24 : 9;
                                }
                            } else if (index == 4) {
                                if (value == 0) {
                                    return '0(凌晨)';
                                } else if (value == 9){
                                    return '9(清晨)';
                                } else {
                                    return value;
                                }
                            }
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgb(152,152,152)'
                        }
                    }
                },
                series: [
                    {
                        data: data,
                        type: 'bar',
                        barWidth: 8,
                        showBackground: false,
                        itemStyle: {
                            color: function (param) {
                                if (that.currTimeType == 'mon') {
                                    let time = that.monTime.split('-');
                                    if (param.dataIndex == time[1] - 1) {
                                        return 'rgb(10,89,246)'
                                    } else {
                                        return 'rgb(158,189,253)'
                                    }
                                } else {
                                    return 'rgb(10,89,246)'
                                }

                            },
                            barBorderRadius: [10, 10, 0, 0]
                        }
                    }
                ]
            };
            myEchart.setOption(option, true);
            //点击事件
            myEchart.on('click', function (params) {
                that.arrIndex[index] = params.dataIndex
                that.arr[index] = params.value
                //柱形图重构
                myEchart.setOption(option);
            })
        },
        getData() {
            let time = this.currTimeType == 'week' ? this.weekTime : this.monTime;
            this.$axios.get('/api/healthkit/readSleep?time=' + time + '&type=' + this.currTimeType, {
                headers: {'token': this.token},
            }).then((response) => {
                let data = response.data;
                this.data = data.data;
                this.createEcharts(this.$refs.sleep1, this.data[0], 0)
                this.createEcharts(this.$refs.sleep2, this.data[1], 1)
                this.createEcharts(this.$refs.sleep3, this.data[2], 2)
                this.createEcharts(this.$refs.sleep4, this.data[3], 3)
                this.createEcharts(this.$refs.sleep5, this.data[4], 4)
                this.count = data.data.count;
            })
        },
        getUrlKey: function (name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)')
                .exec(location.href) || ['', ""])[1].replace(/\+/g, '%20')) || null
        },
        getIsDiary() {
            this.$axios.get('/api/getIsDiary?token=' + this.token).then((response) => {
                let ret = response.data;
                this.btnStatus = (ret.data == '1');
                if (this.btnStatus) this.getData();
            })
        },
    },
    mounted() {
        this.getIsDiary()
        let start = this.GetMonday(new Date());
        let end = this.GetSunday(new Date());
        this.weekTimes = start[1] + '-' + end[1];
        this.weekTime = start[0] + '/' + end[0];
        let mon = this.getMon();
        this.monTime = mon[0];
        this.monTimes = mon[1];
    },
    created() {
        this.token = this.getUrlKey('token');
    },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/sleepData";
</style>
